<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <!-- <b-dropdown-divider /> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="showModal"
    >
      <feather-icon
        size="16"
        icon="LockIcon"
        class="mr-50"
      />
      <span>修改密码</span>
      <b-modal ref="my-modal" v-model="modalVisible" title="修改密码" @ok="confirmAction" @cancel="cancelAction" ok-title="确认" cancel-title="取消">
        <validation-observer ref="simpleRules">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="原始密码"
            label-for="expassword"
          >
            <validation-provider
              #default="{ errors }"
              name="原始密码"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="expassword"
                  v-model="expassword"
                  :type="password1FieldType"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="expassword"
                  placeholder="请输入原始密码"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="password1ToggleIcon"
                    @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="新密码"
            label-for="reset-password-new"
          >
            <validation-provider
              #default="{ errors }"
              name="新密码"
              vid="word"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="reset-password-new"
                  v-model="password"
                  :type="password1FieldType"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="reset-password-new"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="password1ToggleIcon"
                    @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
                label-for="reset-password-confirm"
                label="再次输入新密码"
              >
                <validation-provider
                  #default="{ errors }"
                  name="重新输入的密码"
                  rules="required|confirmed:word"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
        </form>
      </validation-observer>
      </b-modal>
    </b-dropdown-item>
    
   
    
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>退出登录</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BButton, BModal, BCardText,BForm, BFormInput, BFormGroup,BInputGroup,BInputGroupAppend,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { avatarText } from '@core/utils/filter'
import socket from '@/utils/socket'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BCardText,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      modalVisible: false,
      required,
      password2FieldType: 'password',
      password1FieldType: 'password',
      cPassword: '',
      password: '',
      expassword: '',
      name: '',
      submittedNames: [],
      nameState: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      const res = await this.$refs.simpleRules.validate()
      if (!res) {
        return
      }else{
        //this.modalVisible = false;
        const data = {
          cPassword: this.cPassword,
          password: this.password,
          expassword: this.expassword,
        }
        const res = await this.$api.sysusers.changepassword(data)
        if(res.data.code === 200){
          localStorage.clear()
          socket.disconnect();
          this.$router.push({ name: 'auth-login' })
          Vue.$toast({
            component: ToastificationContent,
            position: 'top-left',
            props: {
              title: res.data.msg,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: "密码修改成功请重新登录",
            },
          });
        }else{
          Vue.$toast({
            component: ToastificationContent,
            position: 'top-left',
            props: {
              title: res.data.msg,
              icon: 'CoffeeIcon',
              variant: 'warning',
              text: "修改失败",
            },
          });
          return 
        }
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    confirmAction(bvModalEvt) {
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
      console.log('1111111111111111111111111111111');
    },
    cancelAction() {
      this.modalVisible = false;
      console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
    },
    showModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // Remove userData from localStorage
      localStorage.clear()
      socket.disconnect();
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    getTargetRoute() {
      this.$router.push({ path: '/pages/miscellaneous/not-authorized' })
    }
  },
}
</script>
