export default [
    {
        title: '系统管理',
        icon: 'HomeIcon',
        tagVariant: 'light-warning',
        children: [
            {
              title: '用户管理',
              route: 'userlist',
            },
        ],
    },
    {
        title: '系统管理1',
        icon: 'HomeIcon',
        tagVariant: 'light-warning',
        children: [
            {
              title: '用户管理1',
              route: 'visit',
            },
        ],
    },
    {
        title: '菜单管理',
        icon: 'HomeIcon',
        tagVariant: 'light-warning',
        children: [
            {
              title: '菜单列表',
              route: 'menulist',
            },
        ],
    },
]  