<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state.notice.noticelist.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          通知
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b
        v-for="notification in $store.state.notice.noticelist"
        :key="notification._id"
      >
        <b-media>
          <template #default>
            <div class="custom-media">
              <div class="custom-aside"><b class="colorblue">{{notification.meetperson}}</b> 预约了 <b class="color4e30bf">{{notification.doctor}}</b> 医生</div>
              <div class="custom-aside"><el-button size="mini" @click="isreader(notification._id)">已读</el-button></div>
            </div>
          </template>
        </b-media>
      </b>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="allisreader()"
        v-if="$store.state.notice.noticelist.length > 0"
        >全部已读</b-button>
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        v-if="$store.state.notice.noticelist.length <= 0"
        >没有未读消息</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { computed,ref } from '@vue/composition-api'
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  data(){
    return {
      noticelist:[],
    }
  },
  directives: {
    Ripple,
  },
  created() {
  },
  methods: {
    isreader(id){
      const data = {
        _id:id
      }
      this.$api.notice.isreader(data).then((res) => {
        if(res.data.code === 200){
          store.dispatch('notice/fetchNotice')
        }
      });
    },
    allisreader(){
      this.$api.notice.allisreader().then((res) => {
        if(res.data.code === 200){
          store.dispatch('notice/fetchNotice')
        }
      });
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings
    }
  },
}
</script>

<style>
.custom-media {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.custom-aside{
  display: flex;
  align-items: center;
}
.colorblue{
  color: cornflowerblue;
}
.color4e30bf{
  color: #4e30bf;
}
</style>
